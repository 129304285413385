import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, DropdownMenu, MenuItem, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Emoji from '../../assets/Components/Emoji';
import DEMO from '../../store/constant';
import Avatar2 from '../../../src/assets/images/user/avatar-2.jpg';
import Anne from '../../../src/assets/images/user/Anne.jpeg';
import avatar5 from '../../../src/assets/images/user/avatar-5.jpg';
import avatar3 from '../../../src/assets/images/user/avatar-3.jpg';
import avatar4 from '../../../src/assets/images/user/avatar-4.jpg';
import avatar7 from '../../../src/assets/images/user/img-avatar-2.jpg';
import avatar1 from '../../../src/assets/images/user/avatar-1.jpg';

// import Card from '../../App/components/MainCard';
const E_Chat = () => {
    const [data, setData] = useState({
        isBasic: false,
        isVertically: false,
        isTooltip: false,
        isGrid: false,
        isScrolling: false,
        isLarge: false,
        isVarying: false,
        isSmall: false,
        title: ''
    });
    return (
        <>
            <div className="custom-breadcrumb patcard-breadcrumb">
                <ul>
                    <li>
                        <Link to={DEMO.Dashboard}>
                            <i className="feather icon-home"></i>
                        </Link>
                    </li>
                    <li>/</li>
                    <li>
                        <Link to={DEMO.Notification}>Notification</Link>
                    </li>
                    <li>/</li>
                    <li className="active">E-Connect</li>
                </ul>
            </div>
            <div className="patcard sticky-pcard">
                <div className="user-card user-profile-f1 onscroll">
                    <Card.Header className="border-0 p-0 pb-0 pt-10">
                        <div className="cover-img-block">
                            <div className="overlay" />
                        </div>
                    </Card.Header>
                    <Card.Body className="py-0">
                        <div className="user-about-block m-0">
                            <Row>
                                <div className="user-profile-card-left">
                                    <img className="user-profile-pic" alt="User" src={Anne} />
                                    <div className="user-profile-title">
                                        <Link to={DEMO.Patient_Information}>Anne Perkins</Link>
                                    </div>
                                </div>
                                <Col md={9} className="mt-md-4">
                                    <Row>
                                        <Col>
                                            <span className="pat-desc-text1">
                                                <i className="feather icon-hash mr-2 f-18" />
                                                Patient ID: 2022001
                                            </span>
                                            <span className="pat-desc-text2">
                                                <i className="feather icon-user mr-2 f-18" />
                                                Gender: Female
                                            </span>
                                            <span className="pat-desc-text3">
                                                <i className="feather icon-tag mr-2 f-18" />
                                                Age: 71
                                                {/* <i className="feather icon-calendar mr-2 f-18" />
                                                DOB: 20-6-1951 */}
                                            </span>
                                            {/* <span className="mb-1 text-muted d-flex align-items-end text-h-primary align-right patient_card_readmore">
                                                <Link to={DEMO.Patient_Information}>View More...</Link>
                                            </span> */}
                                        </Col>
                                        {/* <Col>
                                            <div className="mb-1 text-muted d-flex align-items-end text-h-primary">
                                                <i className="feather icon-tag mr-2 f-18" />
                                                Age: 41
                                            </div>
                                            <div className="clearfix" />
                                            <span className="mb-1 text-muted d-flex align-items-end text-h-primary align-right patient_card_readmore">
                                                <Link to={DEMO.Patient_Information}>Read More...</Link>
                                            </span>

                                            <div className="clearfix" />
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </div>
            </div>
            <div class="card" id="eholebody">
                {/* <div class="row g-0"> */}
                {/* <div> */}
                {/* <div class="col-12 col-lg-5 col-xl-3 border-right">*/}
                {/* <div>
                        <div> */}
                <div class="py-1 px-4 border-bottom d-none d-lg-block">
                    <div class="d-flex align-items-center py-1">
                        {/* <Row>
                                                <Col xl={1} md={14}> */}
                        <div class="position-relative">
                            <img src={Anne} class="rounded-circle mr-1" alt="Sharon Lessman" width="30" height="30" />
                        </div>
                        {/* </Col>
                                            <Col  xl={4} md={14}> */}
                        <div class="flex-grow-1 pl-3">
                            <strong>Anne Perkins</strong>
                            <div class="text-muted small">
                                <span class="fas fa-circle chat-online"></span>
                                <em>Typing...</em>
                            </div>
                        </div>
                        {/* </Col>
                                            <Col xl={9} md={14}> */}
                        <div>
                            <Row>
                                <Col>
                                    <a className="nav-link" href={DEMO.VoiceCall} target="_blank">
                                        <i className="fas fa-phone fa-2x" title="Voice Call"></i>
                                        {/* <i className="feather icon-phone mr-5 f-18"/> */}
                                    </a>
                                </Col>
                                <Col>
                                    <a className="nav-link" href={DEMO.VideoCall} target="_blank">
                                        <i className="fas fa-video-camera fa-2x" title="Video Call"></i>
                                        {/* <i className="feather icon-video mr-5 f-18"/> */}
                                    </a>
                                </Col>
                                {/* <Col>
                                                <a className="nav-link" href={DEMO.Patient_Details}>
                                                    <button class="btn btn-light border btn-lg px-1" id="prescriptionbuton">Prescription</button>
                                                    <i className="fa fa-file-prescription fa-2x" title="Prescription"></i>
                                                </a>
                                            </Col> */}
                            </Row>
                        </div>
                        {/* </Col>
                                            </Row> */}
                    </div>
                </div>

                <div class="position-relative" id="ebody">
                    <div class="chat-messages p-4">
                        <div class="chat-message-right pb-2">
                            <div>
                                <img
                                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                    class="rounded-circle mr-1"
                                    alt="Chris Wood"
                                    width="40"
                                    height="40"
                                />
                                <div class="text-muted small text-nowrap mt-2">2:33 am</div>
                            </div>
                            <div class="flex-shrink-1 rounded py-2 px-3 mr-3">
                                <div class="font-weight-bold mb-1">You</div>
                                How are you feeling today.
                            </div>
                        </div>

                        <div class="chat-message-left pb-2">
                            <div>
                                <img src={Anne} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40" />
                                <div class="text-muted small text-nowrap mt-2">2:34 am</div>
                            </div>
                            <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                <div class="font-weight-bold mb-1">Anne Perkins</div>
                                I'm feeling much better than yesterday.
                            </div>
                        </div>

                        <div class="chat-message-right mb-2">
                            <div>
                                <img
                                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                    class="rounded-circle mr-1"
                                    alt="Chris Wood"
                                    width="40"
                                    height="40"
                                />
                                <div class="text-muted small text-nowrap mt-2">2:35 am</div>
                            </div>
                            <div class="flex-shrink-1 rounded py-2 px-3 mr-3">
                                <div class="font-weight-bold mb-1">You</div>
                                What about your pills.
                            </div>
                        </div>

                        <div class="chat-message-left pb-2">
                            <div>
                                <img src={Anne} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40" />
                                <div class="text-muted small text-nowrap mt-2">2:36 am</div>
                            </div>
                            <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                <div class="font-weight-bold mb-1">Anne Perkins</div>
                                Yeah I've taken most of the pills, except the Amlopine AT.
                            </div>
                        </div>

                        <div class="chat-message-left pb-2">
                            <div>
                                <img src={Anne} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40" />
                                <div class="text-muted small text-nowrap mt-2">2:37 am</div>
                            </div>
                            <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                <div class="font-weight-bold mb-1">Anne Perkins</div>
                                When ever I'm Taking that pill feeling dizzy.
                            </div>
                        </div>

                        {/* <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:38 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Oh! I see. Ok you can skip that pill one time in a day for now.
                                                </div>
                                            </div>

                                            <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:39 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">John Doe</div>
                                                    Another thing is from yesterday night I'm getting an headache.
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:40 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Felling any other difficulty? 
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:41 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    please don't forget to take rest of the pills.
                                                </div>
                                            </div> */}

                        {/* <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:42 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">John Doe</div>
                                                    My body temperature is bit high now.
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:43 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Ok I will check the vitals readings of you for today morrning. 
                                                </div>
                                            </div>

                                            <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:44 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">John Doe</div>
                                                    If possible please schedule an appointment for me.
                                                </div>
                                            </div> */}

                        {/* </div> */}
                        {/* </div> */}

                        {/* <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:37 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                                    Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci.
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:38 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                                                </div>
                                            </div> */}

                        {/* <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:39 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                                    Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                                                </div>
                                            </div> */}

                        {/* <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:40 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Cum ea graeci tractatos.
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:41 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Morbi finibus, lorem id placerat ullamcorper, nunc enim ultrices massa, id dignissim metus urna eget purus.
                                                </div>
                                            </div> */}

                        {/* <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:42 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                                    Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit.
                                                    Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus.
                                                </div>
                                            </div>

                                            <div class="chat-message-right mb-4">
                                                <div>
                                                    <img src={avatar5} class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:43 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                                    <div class="font-weight-bold mb-1">You</div>
                                                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                                                </div>
                                            </div> */}

                        {/* <div class="chat-message-left pb-4">
                                                <div>
                                                    <img src={Avatar2} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40"/>
                                                    <div class="text-muted small text-nowrap mt-2">2:44 am</div>
                                                </div>
                                                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                                    Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                                                </div>
                                            </div> */}
                    </div>
                </div>

                <div class="flex-grow-0 py-1 px-4 border-top chat-bt-bar">
                    <div class="input-group">
                        {/* <input type="text" class="form-control" placeholder="Type your message"/>
                                            <button class="btn btn-primary">Send</button> */}
                        <div class="col-sm-1 col-xs-1 reply-emojis">
                            <i class="fas fa-smile-o fa-2x" title="Emojis"></i>
                        </div>
                        <div class="col-sm-1 col-xs-1 reply-emojis1">
                            {/* <input type="file" id="icon-button-file" style={{ display: 'none' }} />
                                        <label htmlFor="icon-button-file">
                                        <i class="fas fa-paperclip fa-2x" title="Attach File"></i>
                                        </label> */}
                            <i
                                class="fas fa-paperclip fa-2x"
                                title="Attach File"
                                onClick={() => setData({ ...data, isVertically: true })}
                            ></i>
                            {/* <i class="fas fa-paperclip fa-2x" title="Attach File"/> */}
                            <Modal
                                className="attach-popup"
                                show={data.isVertically}
                                onHide={() => setData({ ...data, isVertically: false })}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title as="h5">Select file type</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="attach-item">
                                        <input type="file" id="icon-button-file" style={{ display: 'none' }} />
                                        <label htmlFor="icon-button-file">
                                            <i class="fas fa-solid fa-image fa-2x"></i>Images
                                        </label>
                                    </div>
                                    <div className="attach-item">
                                        <input type="file" id="icon-button-file" style={{ display: 'none' }} />
                                        <label htmlFor="icon-button-file">
                                            <i class="fas fa-solid fa-file-video fa-2x"></i>Video File
                                        </label>
                                    </div>
                                    <div className="attach-item">
                                        <input type="file" id="icon-button-file" style={{ display: 'none' }} />
                                        <label htmlFor="icon-button-file">
                                            <i class="fas fa-solid fa-file-pdf fa-2x"></i>Pdf
                                        </label>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div class="col-sm-8 col-xs-8 reply-main">
                            {/* <textarea class="form-control" rows="1" id="comment"></textarea> */}
                            <input type="text" class="form-control" placeholder="Type your message" />
                        </div>
                        <div class="col-sm-1 col-xs-1 reply-recording">
                            <i class="fas fa-microphone fa-2x" aria-hidden="true" title="Say something"></i>
                        </div>
                        <div class="col-sm-1 col-xs-1 reply-send">
                            <i class="fas fa-send fa-2x" aria-hidden="true" title="Send"></i>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
                </div>
            </div> */}
            {/* </div>
                        </main> */}
            {/* </Card> */}
            {/* </Col>
            </Row> */}
        </>
    );
};
export default E_Chat;
