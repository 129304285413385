import * as React from 'react';
import E_Chat from './Demo/E-connect/E-Chat';
// const Dashboard = React.lazy(() => import('./Demo/Dashboard/Dashboard'));
const DashboardMain = React.lazy(() => import('./Demo/Dashboard/DashboardMain'));
const OtherSamplePage = React.lazy(() => import('./Demo/Other/SamplePage'));
// const Econnect = React.lazy(() => import('./Demo/E-connect/Econnect'));
const PatientMonitoringDashboard = React.lazy(() => import('./Demo/PatientMonitoring/PatientMonitoringDashboard'));
const PatientMonitoringDetails = React.lazy(() => import('./Demo/PatientMonitoring/PatientMonitoringDetails'));
const PatientDetails = React.lazy(() => import('./Demo/ConnectDetails/PatientDetails'));
const Vitals = React.lazy(() => import('./Demo/PatientReports/Vitals/Vitals'));
const PhysicianDetails = React.lazy(() => import('./Demo/ConnectDetails/PhysicianDetails'));
const Notification = React.lazy(() => import('./Demo/Dashboard/Notification/Notification'));
const Alerts = React.lazy(() => import('./Demo/Alerts/Alerts'));
const Appointment = React.lazy(() => import('./Demo/Dashboard/Appointment/Appointments'));
//const Prescription = React.lazy(() => import('./Demo/E-connect/Prescription'));
const VoiceCall = React.lazy(() => import('./Demo/E-connect/voicecall'));
const VideoCall = React.lazy(() => import('./Demo/E-connect/Videocall'));
const Accord = React.lazy(() => import('./Demo/Accordion'));

const Comparison = React.lazy(() => import('./Demo/Comparison/Comparison'));

const routes = [
    // { path: '/dashboard', exact: true, name: 'Dashboard Page', component: Dashboard },
    { path: '/Dashboard', exact: true, name: 'Dashboard Page', component: DashboardMain },
    { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage },
    { path: '/appointment', exact: true, name: 'Appointment Page', component: Appointment },
    // { path: '/econnect', exact: true, name: 'E-connect Page', component: Econnect },
    { path: '/patient-monitoring-dashboard', exact: true, name: 'PatientMonitoringDashboard', component: PatientMonitoringDashboard },
    { path: '/patient-monitoring-details', exact: true, name: 'PatientMonitoringDetails Page', component: PatientMonitoringDetails },
    { path: '/CalculationDetails', exact: true, name: 'PatientDetails Page', component: PatientDetails },
    { path: '/Vitals', exact: true, name: 'Vitals', component: Vitals },
    { path: '/physician-details', exact: true, name: 'Physician Details Page', component: PhysicianDetails },
    { path: '/GPCalculation', exact: true, name: 'Appointment Page', component: Alerts },
    { path: '/notification', exact: true, name: 'Notification Page', component: Notification },
    //{ path: '/prescription', exact: true, name: 'Notification Page', component: Prescription },
    { path: '/voice-call', exact: true, name: 'Voice Call', component: VoiceCall },
    { path: '/video-call', exact: true, name: 'Voice Call', component: VideoCall },
    { path: '/accordion', exact: true, name: 'Accordion', component: Accord },
    { path: '/echat', exact: true, name: 'E-chat Page', component: E_Chat },
    { path: '/Comparison', exact: true, name: 'Appointment Page', component: Comparison },
];
export default routes;
