const DEMO = {
    BLANK_LINK: '#',
    PatientMonitoringDashboard: '/patient-monitoring-dashboard',
    Patient_Details: '/CalculationDetails',
    Patient_Information: '/patient-monitoring-details',
    Physician_Details: '/physician-details',
    E_Connect: '/econnect',
    Alerts: '/GPCalculation',
    Comparison: '/Comparison',
    Notification: '/notification',
    Dashboard: '/Dashboard',
    Vitals: '/Vitals',
    // DashboardMain: '/Dashboard',
    Prescription: '/Prescription',
    VoiceCall: '/voice-call',
    VideoCall: '/video-call',
    E_Chat: '/echat',
    Default: '/'
};
export default DEMO;
