import * as React from 'react';
import { Form } from 'react-bootstrap';
const Emoji = () => {
    return (
        <>
            <div className="emoji">
                <Form>
                    <div className="item">
                        <label for="0">
                            <input className="radio" type="radio" name="feedback" id="0" value="0" disabled />
                            <span>😟</span>
                        </label>
                    </div>

                    <div className="item">
                        <label for="1">
                            <input className="radio" type="radio" name="feedback" id="1" value="1" disabled />
                            <span>🙁</span>
                        </label>
                    </div>

                    <div className="item">
                        <label for="2">
                            <input className="radio" type="radio" name="feedback" id="2" value="2" disabled />
                            <span>😐</span>
                        </label>
                    </div>

                    <div className="item">
                        <label for="3">
                            <input className="radio" type="radio" name="feedback" id="3" value="3" disabled />
                            <span>😁</span>
                        </label>
                    </div>

                    <div className="item">
                        <label for="4">
                            <input className="radio" type="radio" name="feedback" id="4" value="4" checked />
                            <span>😍</span>
                        </label>
                    </div>
                </Form>
            </div>
        </>
    );
};
export default Emoji;
